import { Timestamp } from "@firebase/firestore-types";
import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import { ObjectType } from "typescript";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  // resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";

export type Music = {
  id: string;
  title: string;
  composer: string;
  description?: string;
  default?: boolean;
  audio?: {
    path: string;
    url: string;
  };
  category?: string;
  descroption?: string;
  order?: number;
  isPublic?: boolean;
  path?: string;
  url?: string;
  updatedAt?: number;
  createdAt?: number;
};

interface MusicState {
  musicData: Music;
  allMusics: Array<Music>;
  getMusicStatus: ActionStatus;
  syncMusicDataStatus: ActionStatus;
  postMusicDataStatus: ActionStatus;
  updateMusicDataStatus: ActionStatus;
  deleteMusicDataStatus: ActionStatus;
  getAllMusicsStatus: ActionStatus;
}
// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: MusicState = {
  musicData: {
    id: "",
    title: "",
    composer: "",
    description: "",
    audio: {
      path: "",
      url: "",
    },
    category: "",
    isPublic: false,
    path: "",
    url: "",
    // status는 loading, done, error을 갖고있음.
  },
  allMusics: [],
  getMusicStatus: createStatus(),
  syncMusicDataStatus: createStatus(),
  postMusicDataStatus: createStatus(),
  updateMusicDataStatus: createStatus(),
  deleteMusicDataStatus: createStatus(),
  getAllMusicsStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  GET_MUSIC_REQUEST: "GET_MUSIC_REQUEST",
  GET_MUSIC_SUCCESS: "GET_MUSIC_SUCCESS",
  GET_MUSIC_FAILURE: "GET_MUSIC_FAILURE",
  POST_MUSIC_REQUEST: "POST_MUSIC_REQUEST",
  POST_MUSIC_SUCCESS: "POST_MUSIC_SUCCESS",
  POST_MUSIC_FAILURE: "POST_MUSIC_FAILURE",
  UPDATE_MUSIC_REQUEST: "UPDATE_MUSIC_REQUEST",
  UPDATE_MUSIC_SUCCESS: "UPDATE_MUSIC_SUCCESS",
  UPDATE_MUSIC_FAILURE: "UPDATE_MUSIC_FAILURE",
  DELETE_MUSIC_REQUEST: "DELETE_MUSIC_REQUEST",
  DELETE_MUSIC_SUCCESS: "DELETE_MUSIC_SUCCESS",
  DELETE_MUSIC_FAILURE: "DELETE_MUSIC_FAILURE",
  GET_ALL_MUSICS_REQUEST: "GET_ALL_MUSICS_REQUEST",
  GET_ALL_MUSICS_SUCCESS: "GET_ALL_MUSICS_SUCCESS",
  GET_ALL_MUSICS_FAILURE: "GET_ALL_MUSICS_FAILURE",
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const getMusicAction = (id: string) => ({
  type: actions.GET_MUSIC_REQUEST,
  id,
});
export const postMusicAction = (data: Music) => ({
  type: actions.POST_MUSIC_REQUEST,
  data,
});
export const updateMusicAction = (id: string, data: Music) => ({
  type: actions.UPDATE_MUSIC_REQUEST,
  id,
  data,
});

export const getAllMusicsAction = (isPublic: boolean) => ({
  type: actions.GET_ALL_MUSICS_REQUEST,
  isPublic,
});
export const deleteMusicAction = (id: string) => ({
  type: actions.DELETE_MUSIC_REQUEST,
  id,
});
// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_MUSIC_REQUEST:
        draft.getMusicStatus = createRequestStatus();
        break;
      case actions.GET_MUSIC_SUCCESS:
        draft.getMusicStatus = createSuccessStatus();
        draft.musicData = action.data;
        break;
      case actions.GET_MUSIC_FAILURE:
        draft.getMusicStatus = createFailureStatus(action.error);
        break;
      case actions.POST_MUSIC_REQUEST:
        draft.postMusicDataStatus = createRequestStatus();
        break;
      case actions.POST_MUSIC_SUCCESS:
        draft.postMusicDataStatus = createSuccessStatus();
        break;
      case actions.POST_MUSIC_FAILURE:
        draft.postMusicDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_MUSIC_REQUEST:
        draft.updateMusicDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_MUSIC_SUCCESS:
        draft.updateMusicDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_MUSIC_FAILURE:
        draft.updateMusicDataStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_MUSIC_REQUEST:
        draft.deleteMusicDataStatus = createRequestStatus();
        break;
      case actions.DELETE_MUSIC_SUCCESS:
        draft.deleteMusicDataStatus = createSuccessStatus();
        break;
      case actions.DELETE_MUSIC_FAILURE:
        draft.deleteMusicDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_MUSICS_REQUEST:
        draft.getAllMusicsStatus = createRequestStatus();
        break;
      case actions.GET_ALL_MUSICS_SUCCESS:
        draft.getAllMusicsStatus = createSuccessStatus();
        draft.allMusics = action.data;
        break;
      case actions.GET_ALL_MUSICS_FAILURE:
        draft.getAllMusicsStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
