import React, { useEffect } from "react";
import type { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import GoogleAnalytics from "./components/GoogleAnalytics";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import routes, { renderRoutes } from "./routes";
import { initializeI18next, i18n } from "./plugins/i18next";
import { db, firestore } from "./lib/firebase";
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();
  useEffect(() => {
    initializeI18next();
  }, []);
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });
  useEffect(() => {
    const OBJECT_LIST = [
      {
        areaSize: {
          width: 190,
          height: 70,
          depth: 110,
        },
      },
    ];
    const SPACE_LIST = [
      {
        id: "grandmercure_01",
        url: "https://my.matterport.com/show/?m=kRbbUQcK9U7",
        title: "그랜드볼룸",
        matterportId: "kRbbUQcK9U7",
        showcase: "kRbbUQcK9U7",
        logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fq0u0vf_8ayr-1mgoy66_logo.png?alt=media&token=cde649ca-f3b2-4d5f-a552-fd587e796336",
        defaultRotation: 0,
      },
      {
        id: "grandmercure_02",
        url: "https://my.matterport.com/show/?m=13LUiCRVwSM",
        title: "빌라드룸",
        matterportId: "13LUiCRVwSM",
        showcase: "13LUiCRVwSM",
        logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fq0u0vf_8ayr-1mgoy66_logo.png?alt=media&token=cde649ca-f3b2-4d5f-a552-fd587e796336",
        defaultRotation: 0,
      },
      {
        id: "grandmercure_03",
        url: "https://my.matterport.com/show/?m=GirzcsETiF3",
        title: "가든하우스",
        matterportId: "GirzcsETiF3",
        showcase: "GirzcsETiF3",
        logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fq0u0vf_8ayr-1mgoy66_logo.png?alt=media&token=cde649ca-f3b2-4d5f-a552-fd587e796336",
        defaultRotation: 0,
      },
      // {
      //   id: "fe01_1",
      //   url: "https://my.matterport.com/show/?m=rq8VD58Ui8s",
      //   title: "Fe01 버거",
      //   matterportId: "rq8VD58Ui8s",
      //   showcase: "rq8VD58Ui8s",
      //   logo: "",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "fe01_2",
      //   url: "https://my.matterport.com/show/?m=yTNt7xiPGps",
      //   title: "Fe01 버거",
      //   matterportId: "yTNt7xiPGps",
      //   showcase: "yTNt7xiPGps",
      //   logo: "",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "fe01_3",
      //   url: "https://my.matterport.com/show/?m=3ze22NWEjSP",
      //   title: "Fe01 버거",
      //   matterportId: "3ze22NWEjSP",
      //   showcase: "3ze22NWEjSP",
      //   logo: "",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "ceco_01",
      //   url: "https://my.matterport.com/show/?m=hqP975niAUj",
      //   title: "CECO 컨벤션홀",
      //   matterportId: "hqP975niAUj",
      //   showcase: "hqP975niAUj",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_10-07-54.png?alt=media&token=b5cffc6f-9cd1-4146-8a94-c2a3a03a87df",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "ceco_02",
      //   url: "https://my.matterport.com/show/?m=ys1kp5NFghi",
      //   title: "회의실 301~302",
      //   matterportId: "ys1kp5NFghi",
      //   showcase: "ys1kp5NFghi",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_10-07-54.png?alt=media&token=b5cffc6f-9cd1-4146-8a94-c2a3a03a87df",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "lotte_01",
      //   url: "https://my.matterport.com/show/?m=Srfv93dg4jK",
      //   title: "샤롯데 연회장",
      //   matterportId: "Srfv93dg4jK",
      //   showcase: "Srfv93dg4jK",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-b7783.appspot.com/o/icons%2F2023-11-20_11-01-36.png?alt=media&token=51ff5389-f62f-47ed-872f-bf1056ffabaa",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_01",
      //   url: "https://my.matterport.com/models/MsvBTu8jzFG",
      //   title: "1전시장 1F 다목적전시장(109호~112호)",
      //   matterportId: "MsvBTu8jzFG",
      //   showcase: "MsvBTu8jzFG",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_02",
      //   url: "https://my.matterport.com/models/VjcTUpwnuRx",
      //   title: "1전시장 1F 중회의장(101호 ~108호)",
      //   matterportId: "VjcTUpwnuRx",
      //   showcase: "VjcTUpwnuRx",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_03",
      //   url: "https://my.matterport.com/models/jo4rUb1Ucvw",
      //   title: "1전시장 2F 그랜드볼륨",
      //   matterportId: "jo4rUb1Ucvw",
      //   showcase: "jo4rUb1Ucvw",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_04",
      //   url: "https://my.matterport.com/models/uVuDcAroGyc",
      //   title: "1전시장 2F 중회의실(204~205호)",
      //   matterportId: "uVuDcAroGyc",
      //   showcase: "uVuDcAroGyc",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_05",
      //   url: "https://my.matterport.com/models/YTChCS6B9EZ",
      //   title: "1전시장 2F 소회의실(206호~208호)",
      //   matterportId: "YTChCS6B9EZ",
      //   showcase: "YTChCS6B9EZ",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_06",
      //   url: "https://my.matterport.com/models/DzAygR7F82f",
      //   title: "1전시장 2F 소회의실(209호~211호)",
      //   matterportId: "DzAygR7F82f",
      //   showcase: "DzAygR7F82f",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_07",
      //   url: "https://my.matterport.com/models/ezmYxroNMaB",
      //   title: "2전시장 전시홀(1~4홀)",
      //   matterportId: "ezmYxroNMaB",
      //   showcase: "ezmYxroNMaB",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_08",
      //   url: "https://my.matterport.com/models/ZCk42KLL7om",
      //   title: "2전시장 3F 301호",
      //   matterportId: "ZCk42KLL7om",
      //   showcase: "ZCk42KLL7om",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_09",
      //   url: "https://my.matterport.com/models/58WGmeWzyBo",
      //   title: "2전시장 3F 302호",
      //   matterportId: "58WGmeWzyBo",
      //   showcase: "58WGmeWzyBo",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
      // {
      //   id: "dcc_10",
      //   url: "https://my.matterport.com/models/PXmsK5T7EDa",
      //   title: "2전시장 3F 303호 배치1전",
      //   matterportId: "PXmsK5T7EDa",
      //   showcase: "PXmsK5T7EDa",
      //   logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2F2023-11-08_17-04-13.png?alt=media&token=3792ab44-92fc-46ea-8871-722f87430b9b",
      //   defaultRotation: 0,
      // },
    ];

    // SPACE_LIST.forEach(item => {
    //   // firestore().collection("Exhibition").doc(item.id).set(item);
    //   firestore()
    //     .collection("SimulationModel")
    //     .get()
    //     .then(snap => {
    //       const list = [];
    //       snap.forEach(doc => {
    //         if(doc.data().depth === 75)
    //         list.push({ id: doc.id, data: doc.data() });
    //       else if (doc.data().depth)
    //       });
    //       list.forEach(({ id, data }) => {
    //         firestore().collection("Exhibition").doc(item.id).collection("Model").doc(id).set(data);
    //       });
    //     });
    // });
    const ceco_objects = [
      {
        areaSize: {
          width: 183,
          height: 75,
          depth: 140,
        },
        diameter: null,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Fsquare_table_1.8_0.75_m_3_chairs_c_ff.fbx?alt=media&token=8045d56e-ba19-4423-be4c-8f60059bc55e",
        name: "Desk",
        numberOfChairs: 3,
        objectType: "table",
        shape: "square",
        size: {
          height: 75,
          width: 180,
          depth: 75,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 183,
          height: 75,
          depth: 140,
        },
        diameter: null,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Fsquare_table_1.8_0.75_m_2_chairs_c_ff.fbx?alt=media&token=9f8bcf63-7e9e-43cf-9df8-35f710801a78",
        name: "Desk",
        numberOfChairs: 2,
        objectType: "table",
        shape: "square",
        size: {
          height: 75,
          width: 180,
          depth: 75,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 183,
          height: 75,
          depth: 140,
        },
        diameter: null,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Fsquare_table_1.8_0.75_m_1_chairs_c_ff.fbx?alt=media&token=bec99828-2ddb-4cc5-a02d-3fa20cbfd268",
        name: "Desk",
        numberOfChairs: 1,
        objectType: "table",
        shape: "square",
        size: {
          height: 75,
          width: 180,
          depth: 75,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 183,
          height: 75,
          depth: 140,
        },
        diameter: null,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Fsquare_table_1.8_0.75_m_0_chairs_c_ff.fbx?alt=media&token=87e4a211-8954-482a-9d8c-835767f7cb15",
        name: "Desk",
        numberOfChairs: 0,
        objectType: "table",
        shape: "square",
        size: {
          height: 75,
          width: 180,
          depth: 75,
        },
        type: "fbx",
      },

      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: true,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_8_chairs.fbx?alt=media&token=246ba456-5033-4f38-8eae-4b95f3b13f7b",
        name: "Round Table",
        numberOfChairs: 8,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_7_chairs.fbx?alt=media&token=d0d86e87-cc94-449e-98fd-be0524ba01fa",
        name: "Round Table",
        numberOfChairs: 7,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_6_chairs.fbx?alt=media&token=92c7da7a-499c-4a56-bdfb-d6ba541ef360",
        name: "Round Table",
        numberOfChairs: 6,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_5_chairs.fbx?alt=media&token=c8942ecb-cd7d-42ec-b906-2cdb94d27024",
        name: "Round Table",
        numberOfChairs: 5,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_4_chairs.fbx?alt=media&token=169aa11f-a3e9-450c-a83a-3a9088080c92",
        name: "Round Table",
        numberOfChairs: 4,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_3_chairs.fbx?alt=media&token=eb80cb81-3dfc-4e02-b78f-38c378c7bd3a",
        name: "Round Table",
        numberOfChairs: 3,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_2_chairs.fbx?alt=media&token=a204267c-9a18-4e0f-b050-6ff4210e9617",
        name: "Round Table",
        numberOfChairs: 2,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_1_chairs.fbx?alt=media&token=1a32c8dc-d012-43c3-b144-f3e153ce06f2",
        name: "Round Table",
        numberOfChairs: 1,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
      {
        areaSize: {
          width: 280,
          height: 120,
          depth: 280,
        },
        diameter: 160,
        hasChair: true,
        isDefault: false,
        modelUrl:
          "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/floorplan_objects%2Ftable_1.6m_0_chairs.fbx?alt=media&token=2ab2a799-c8cd-47d8-b691-55c55f5988b4",
        name: "Round Table",
        numberOfChairs: 0,
        objectType: "table",
        shape: "round",
        size: {
          height: null,
          width: 70,
          depth: null,
        },
        type: "fbx",
      },
    ];

    // firestore()
    //   .collection("Exhibition")
    //   .get()
    //   .then(snapshot => {
    //     snapshot.forEach(doc => {
    //       if (doc.data().isDeleted == null) {
    //         doc.ref.update({
    //           isDeleted: false,
    //         });
    //       }
    //     });
    //   });
    // ceco_objects.forEach(item => {
    //   firestore().collection("SimulationModel").add(item);
    // });
    // firesto
    // ceco.forEach(item => {
    //   firestore()
    //     .collection("Exhibition")
    //     .doc(item)
    //     .collection("Model")
    //     .get()
    //     .then(snap => {
    //       snap.forEach(doc => {
    //         if (doc.data().numberOfChars !== undefined) {
    //           doc.ref.update({ numberOfChairs: doc.data().numberOfChars });
    //         }
    //       });
    //     });
    // });

    // ceco.forEach(item => {
    //     });
    // const data = [
    //   {
    //     id: "hico_00",
    //     url: "https://my.matterport.com/show/?m=AjgJUNPKFDZ",
    //     matterportId: "AjgJUNPKFDZ",
    //     showcase: "AjgJUNPKFDZ",
    //     defaultRotate: 0,
    //     logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fabout_logo02_03.jpg?alt=media&token=6aaabfe1-8716-46a7-b6bf-18480c952e36&_gl=1*1c4lrqf*_ga*MTc5NzUxNzI3MC4xNjkyOTM3ODg3*_ga_CW55HF8NVT*MTY5ODI5Mjk1Ni4xMTIuMS4xNjk4MjkzMTIyLjQ4LjAuMA..",
    //     title: "101 ~ 106",
    //   },
    //   {
    //     id: "hico_01",
    //     url: "https://my.matterport.com/show/?m=6DLjAJ6oyG8",
    //     matterportId: "6DLjAJ6oyG8",
    //     showcase: "6DLjAJ6oyG8",
    //     defaultRotate: 0,
    //     logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fabout_logo02_03.jpg?alt=media&token=6aaabfe1-8716-46a7-b6bf-18480c952e36&_gl=1*1c4lrqf*_ga*MTc5NzUxNzI3MC4xNjkyOTM3ODg3*_ga_CW55HF8NVT*MTY5ODI5Mjk1Ni4xMTIuMS4xNjk4MjkzMTIyLjQ4LjAuMA..",
    //     title: "1~2홀",
    //   },
    //   {
    //     id: "hico_02",
    //     url: "https://my.matterport.com/show/?m=5kSriGnnSLf",
    //     matterportId: "5kSriGnnSLf",
    //     showcase: "5kSriGnnSLf",
    //     defaultRotate: 0,
    //     logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fabout_logo02_03.jpg?alt=media&token=6aaabfe1-8716-46a7-b6bf-18480c952e36&_gl=1*1c4lrqf*_ga*MTc5NzUxNzI3MC4xNjkyOTM3ODg3*_ga_CW55HF8NVT*MTY5ODI5Mjk1Ni4xMTIuMS4xNjk4MjkzMTIyLjQ4LjAuMA..",
    //     title: "201~206",
    //   },
    //   {
    //     id: "hico_03",
    //     url: "https://my.matterport.com/show/?m=iBTcBpJzKGL",
    //     matterportId: "iBTcBpJzKGL",
    //     showcase: "iBTcBpJzKGL",
    //     defaultRotate: 0,
    //     logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fabout_logo02_03.jpg?alt=media&token=6aaabfe1-8716-46a7-b6bf-18480c952e36&_gl=1*1c4lrqf*_ga*MTc5NzUxNzI3MC4xNjkyOTM3ODg3*_ga_CW55HF8NVT*MTY5ODI5Mjk1Ni4xMTIuMS4xNjk4MjkzMTIyLjQ4LjAuMA..",
    //     title: "300A ~ C",
    //   },
    //   {
    //     id: "hico_04",
    //     url: "https://my.matterport.com/show/?m=kakSp9MRGBk",
    //     matterportId: "kakSp9MRGBk",
    //     showcase: "kakSp9MRGBk",
    //     defaultRotate: 0,
    //     logo: "https://firebasestorage.googleapis.com/v0/b/onna-floorplanning.appspot.com/o/logo%2Fabout_logo02_03.jpg?alt=media&token=6aaabfe1-8716-46a7-b6bf-18480c952e36&_gl=1*1c4lrqf*_ga*MTc5NzUxNzI3MC4xNjkyOTM3ODg3*_ga_CW55HF8NVT*MTY5ODI5Mjk1Ni4xMTIuMS4xNjk4MjkzMTIyLjQ4LjAuMA..",
    //     title: "레스토랑 라운지 4F",
    //   },
    // ];
    // data.forEach(item => {
    //   db.collection("Exhibition").doc(item.id).set(item);
    //   console.log(item);
    // });
    // console.log("object_asdfasdf");
    // object.forEach(item => {
    //   db.collection("SimulationModel").add(item);
    //   console.log(item);
    // });
    // db.collection("SimulationModel").add();
  }, []);
  // useEffect(() => {
  //   db.collection("Exhibition")
  //     .doc("znPcDqlJGdQP2qC4A3J9")
  //     .collection("Videos")
  //     .get()
  //     .then((snap) => {
  //       const result = [];
  //       snap.forEach((doc) => {
  //         result.push({ id: doc.id, data: doc.data() });
  //       });
  //       result.forEach(({ id, data }) => {
  //         db.collection("Exhibition").doc("CFGMyjlpARWAT5hmRua9").collection("Videos").doc(id).set(data);
  //       });
  //     });
  // }, []);
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
