export const APP_VERSION = '1.0.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const GLOBAL_DATA_KEY = 'JBe4n9GSJT2fiadbN0rc';