import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  // resetStatus,
  ActionStatus,
} from '../utils/reducerUtils';

interface ProductState {
  exhibitionId: string;
  plan: string;
  getProductStatus: ActionStatus;
}
// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: ProductState = {
  exhibitionId: '',
  plan: '',
  // status는 loading, done, error을 갖고있음.
  getProductStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  SET_PRODUCT_REQUEST: 'SET_PRODUCT_REQUEST',
  SET_PRODUCT_SUCCESS: 'SET_PRODUCT_SUCCESS',
  SET_PRODUCT_FAILURE: 'SET_PRODUCT_FAILURE',
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const setProduct = (exhibitionId: string, plan: string) => ({
  type: actions.SET_PRODUCT_REQUEST,
  exhibitionId,
  plan,
});

// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.SET_PRODUCT_REQUEST:
        draft.getProductStatus = createRequestStatus();
        break;
      case actions.SET_PRODUCT_SUCCESS:
        draft.getProductStatus = createSuccessStatus();
        draft.exhibitionId = action.data.exhibitionId;
        draft.plan = action.data.plan;
        break;
      case actions.SET_PRODUCT_FAILURE:
        draft.getProductStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
