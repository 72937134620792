import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";

type Global = {
  value: string;
};

interface GlobalState {
  globalData: Global;
  menuButton: boolean; // 메뉴버튼이 켜져있는지, 꺼져있는지를 나타냄.
  screen: {
    width: "xs" | "sm" | "md" | "lg" | "xl";
    orientation: string; // 'landscape' | 'portrait';
  };
  globalOption: {
    // 메뉴 내부의 버튼이 켜져있는지, 껴져있는지를 나타냄.
    infoButton: boolean;
    chatButton: boolean;
    BGMButton: boolean;
    detailedInfo: boolean;
  };
  // 도슨트 아이콘 타입
  docentIconType?: Array<{
    id: string;
    type: string;
    url: string;
  }>;
  isSpaceLoaded: boolean; // 공간 로딩 체크
  syncGlobalDataStatus: ActionStatus;
  getGlobalDataStatus: ActionStatus;
  updateGlobalDataStatus: ActionStatus;
  getDocentIconTypeStatus: ActionStatus;
}
// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: GlobalState = {
  globalData: {
    value: "",
  },
  menuButton: false, // 기본값 꺼져있음
  screen: {
    width: null,
    orientation: screen.orientation ? screen.orientation.type.split("-")[0] : "landscape",
  },
  globalOption: {
    infoButton: false,
    chatButton: false,
    BGMButton: true,
    detailedInfo: false,
  },
  isSpaceLoaded: false,
  docentIconType: [],
  // status는 loading, done, error을 갖고있음.
  syncGlobalDataStatus: createStatus(),
  getGlobalDataStatus: createStatus(),
  updateGlobalDataStatus: createStatus(),
  getDocentIconTypeStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  SYNC_GLOBAL_DATA_REQUEST: "SYNC_GLOBAL_DATA_REQUEST",
  SYNC_GLOBAL_DATA_SUCCESS: "SYNC_GLOBAL_DATA_SUCCESS",
  SYNC_GLOBAL_DATA_FAILURE: "SYNC_GLOBAL_DATA_FAILURE",
  GET_GLOBAL_DATA_REQUEST: "GET_GLOBAL_DATA_REQUEST",
  GET_GLOBAL_DATA_SUCCESS: "GET_GLOBAL_DATA_SUCCESS",
  GET_GLOBAL_DATA_FAILURE: "GET_GLOBAL_DATA_FAILURE",
  UPDATE_GLOBAL_DATA_REQUEST: "UPDATE_GLOBAL_DATA_REQUEST",
  UPDATE_GLOBAL_DATA_SUCCESS: "UPDATE_GLOBAL_DATA_SUCCESS",
  UPDATE_GLOBAL_DATA_FAILURE: "UPDATE_GLOBAL_DATA_FAILURE",
  UPDATE_GLOBAL_DATA_STATUS_RESET: "UPDATE_GLOBAL_DATA_STATUS_RESET",
  SYNC_GLOBAL_DATA_DONE: "SYNC_GLOBAL_DATA_DONE",
  SYNC_GET_POINTS_DONE: "SYNC_GET_POINTS_DONE",
  TOGGLE_GLOBAL_OPTION_CHANGE: "TOGGLE_GLOBAL_OPTION_CHANGE",
  TOGGLE_MENU_BUTTON: "TOGGLE_MENU_BUTTON",
  SET_SCREEN_SIZE: "SET_SCREEN_SIZE",
  GET_DOCENT_ICON_TYPE_REQUEST: "GET_DOCENT_ICON_TYPE_REQUEST",
  GET_DOCENT_ICON_TYPE_SUCCESS: "GET_DOCENT_ICON_TYPE_SUCCESS",
  GET_DOCENT_ICON_TYPE_FAILURE: "GET_DOCENT_ICON_TYPE_FAILURE",
  SET_SPACE_LOADED: "SET_SPACE_LOADED",
  SET_SPACE_UNLOADED: "SET_SPACE_UNLOADED",
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const getGlobalDataAction = () => ({
  type: actions.GET_GLOBAL_DATA_REQUEST,
});
export type OptionType = "infoButton" | "chatButton" | "BGMButton" | "detailedInfo";
//인자로 가진 globalOption을 켜고 끄는 액션
export const toggleGlobalOptionChangeAction = (target: OptionType) => ({
  type: actions.TOGGLE_GLOBAL_OPTION_CHANGE,
  target,
});
export const toggleMenuButtonAction = (forced: boolean = false) => ({
  type: actions.TOGGLE_MENU_BUTTON,
  forced,
});
export const syncGlobalDataAction = () => ({
  type: actions.SYNC_GLOBAL_DATA_REQUEST,
});
export const updateGlobalDataAction = (target, value) => ({
  type: actions.UPDATE_GLOBAL_DATA_REQUEST,
  target,
  value,
});
export const syncGlobalDataDoneAction = () => ({
  type: actions.SYNC_GLOBAL_DATA_DONE,
});
export const resetStatusAction = (type) => ({ type });
export const setScreenSizeAction = (width: string, orientation: ScreenOrientation) => ({
  type: actions.SET_SCREEN_SIZE,
  width,
  orientation: orientation.type.split("-")[0],
});
export const setSpaceLoaded = () => ({
  type: actions.SET_SPACE_LOADED,
});
export const setSpaceUnloaded = () => ({
  type: actions.SET_SPACE_UNLOADED,
});
export const getDocentIconTypeAction = () => ({ type: actions.GET_DOCENT_ICON_TYPE_REQUEST });
// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.SYNC_GLOBAL_DATA_REQUEST:
        draft.syncGlobalDataStatus = createRequestStatus();
        break;
      case actions.SYNC_GLOBAL_DATA_SUCCESS:
        draft.syncGlobalDataStatus = createSuccessStatus();
        draft.globalData = action.data;
        break;
      case actions.SYNC_GLOBAL_DATA_FAILURE:
        draft.syncGlobalDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_GLOBAL_DATA_REQUEST:
        draft.getGlobalDataStatus = createRequestStatus();
        break;
      case actions.GET_GLOBAL_DATA_SUCCESS:
        draft.getGlobalDataStatus = createSuccessStatus();
        draft.globalData = action.data;
        break;
      case actions.GET_GLOBAL_DATA_FAILURE:
        draft.getGlobalDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_GLOBAL_DATA_REQUEST:
        draft.updateGlobalDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_GLOBAL_DATA_SUCCESS:
        draft.updateGlobalDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_GLOBAL_DATA_FAILURE:
        draft.updateGlobalDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_GLOBAL_DATA_STATUS_RESET:
        draft.updateGlobalDataStatus = resetStatus();
        break;
      case actions.TOGGLE_GLOBAL_OPTION_CHANGE:
        draft.globalOption[action.target] = !draft.globalOption[action.target];
        break;
      case actions.TOGGLE_MENU_BUTTON:
        draft.menuButton = !draft.menuButton;
        break;
      case actions.GET_DOCENT_ICON_TYPE_REQUEST:
        draft.getDocentIconTypeStatus = createRequestStatus();
        break;
      case actions.GET_DOCENT_ICON_TYPE_SUCCESS:
        draft.docentIconType = action.data;
        draft.getDocentIconTypeStatus = createSuccessStatus();
        break;
      case actions.GET_DOCENT_ICON_TYPE_FAILURE:
        draft.getDocentIconTypeStatus = createFailureStatus(action.error);
        break;
      case actions.SET_SCREEN_SIZE:
        draft.screen.width = action.width;
        if (action.width === "xs" && document.body.clientHeight > document.body.clientWidth) {
          draft.screen.orientation = "portrait";
        } else draft.screen.orientation = action.orientation;
        break;
      case actions.SET_SPACE_LOADED:
        draft.isSpaceLoaded = true;
        break;
      case actions.SET_SPACE_UNLOADED:
        draft.isSpaceLoaded = false;
        break;
      default:
        break;
    }
  });

export default reducer;
