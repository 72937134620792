// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic.css);"]);
exports.push([module.id, "@import url(https://hangeul.pstatic.net/hangeul_static/css/nanum-myeongjo.css);"]);
exports.push([module.id, "@import url(https://hangeul.pstatic.net/hangeul_static/css/nanum-pen.css);"]);
// Module
exports.push([module.id, ".frame {\n  height: 100%;\n  width: 100%;\n  border-width: 0px;\n}\n\n.main {\n  height: 100%;\n  width: 100%;\n}\n* {\n  word-break: keep-all;\n}\n\nhtml {\n  font-family: \"SpoqaHanSansNeo\";\n}\n\n.list-wrapper {\n  z-index: 100;\n  position: fixed;\n  right: 10px;\n  top: 10px;\n  float: right;\n  width: 120px;\n  background-color: whitesmoke;\n}\n\n.MuiTooltip-tooltipPlacementTop {\n  font-size: 14px !important;\n  padding: 10px !important;\n}\n", ""]);
// Exports
module.exports = exports;
