// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./SpoqaHanSansNeo-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./SpoqaHanSansNeo-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./SpoqaHanSansNeo-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./SpoqaHanSansNeo-Regular.eot?iefix");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face {\n  /* 사용할 폰트의 이름 */\n  font-family: 'SpoqaHanSansNeo';\n  font-display: fallback;\n\n  /* \n  src: 사용할 폰트의 위치 및 형식 \n    Safari, Android, iOS => ttf\n    Modern Browsers => woff\n    IE6-IE8 => eot\n  */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('embedded-opentype');\n\n  /* 사용할 폰트의 기본 스타일 */\n  font-weight: 400;\n  font-style: normal;\n}\n", ""]);
// Exports
module.exports = exports;
