import { all, fork } from 'redux-saga/effects';

import globalSaga from './global';
import musicSaga from './music';
import chatSaga from './chat';
import exhibitionSaga from './exhibition';
import collectionSaga from './collection';
import spaceSaga from './space';
import productSaga from './product';

export default function* rootSaga() {
  yield all([
    fork(globalSaga),
    fork(musicSaga),
    fork(chatSaga),
    fork(exhibitionSaga),
    fork(collectionSaga),
    fork(spaceSaga),
    fork(productSaga),
  ]);
}
