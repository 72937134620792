export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: "AIzaSyANVlAlNps_2H55fOTGOXKYU5ZUjEijLvU",
  authDomain: "onna-floorplanning.firebaseapp.com",
  projectId: "onna-floorplanning",
  storageBucket: "onna-floorplanning.appspot.com",
  messagingSenderId: "851467672163",
  appId: "1:851467672163:web:07d99a14cc2257777d3656",
  measurementId: "G-RNJPVCW8TN",
};
