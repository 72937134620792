import React, { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import type { User } from "../../src/types/user";
import SplashScreen from "../../src/components/SplashScreen";
import firebase from "../../src/lib/firebase";
import { db } from "../../src/lib/firebase";
import { useParams } from "react-router-dom";

import store, { useSelector } from "../store";
declare global {
  interface Window {
    Kakao: any;
    IMP: any;
    jQuery: any;
  }
}
interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isPhone: boolean;
  user: User | null;
  isCloudAdmin?: boolean;
}

interface AuthContextValue extends AuthState {
  method: "FirebaseAuth";
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithEmailAndPasswordCloud: (email: string, password: string, isAdmin?: boolean) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signInWithKakao: () => Promise<any>;
  logout: () => Promise<void>;
  updateUserState;
  getKakaoAccessToken;
  getKakaoAccessTokenCloud;
  updateUserProfile;
  saveUserState;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: "AUTH_STATE_CHANGED" | "SET_CLOUD_ADMIN";
  payload: {
    isAuthenticated?: boolean;
    isPhone?: boolean;
    user?: User | null;
    isCloudAdmin?: boolean;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isPhone: false,
  isInitialized: false,
  user: null,
  isCloudAdmin: false,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, isPhone, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isPhone,
        isInitialized: true,
        user,
      };
    }
    case "SET_CLOUD_ADMIN": {
      const { isCloudAdmin } = action.payload;
      return {
        ...state,
        isCloudAdmin,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "FirebaseAuth",
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPasswordCloud: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithKakao: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getKakaoAccessToken: () => Promise.resolve(),
  getKakaoAccessTokenCloud: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  updateUserState: () => {},
  saveUserState: () => {},
});
type RouteParams = {
  clientId: string;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const history = useHistory();
  const { Kakao } = window;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const clientId = window.location.pathname.split("/")[1];
  // const clientId = sessionStorage.getItem("clientId");

  // db에 유저정보 저장
  const updateUserProfile = (uid, email, userName, social) => {
    db.collection("User")
      .doc(uid)
      .set({
        uid,
        email,
        status: "user",
        userName,
        social,
        createdAt: firebase.firestore.Timestamp.now(),
        phone: "",
        avatar: "",
        introduce: "",
        countryCode: "",
        agreeEmail: false,
        agreeKakao: false,
      })
      .then(() => {
        db.collection("Collection")
          .doc(uid)
          .set({
            id: uid,
            exhibitions: [],
          })
          .then(() => {
            history.push({
              pathname: "/join2",
            });
          });
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  const saveUserState = (kakaoUser, user, phone, social) => {
    dispatch({
      type: "AUTH_STATE_CHANGED",
      payload: {
        isAuthenticated: true,
        isPhone: phone ? true : false,
        user: {
          id: kakaoUser?.id || user?.uid,
          avatar: kakaoUser?.avatar || user?.avatar,
          email: kakaoUser?.email || user?.email,
          name: kakaoUser?.name || user?.userName,
          introduce: kakaoUser?.introduce || user?.introduce,
          countryCode: kakaoUser?.countryCode || user?.countryCode,
          agreeMarketing: kakaoUser?.agreeMarketing || user?.agreeMarketing,
          agreeEmail: kakaoUser?.agreeEmail || user?.agreeEmail,
          agreeKakao: kakaoUser?.agreeKakao || user?.agreeKakao,
          phone,
          social,
        },
      },
    });
  };

  const updateUserState = (user) => {
    dispatch({
      type: "AUTH_STATE_CHANGED",
      payload: {
        isAuthenticated: true,
        isPhone: true,
        user,
      },
    });
  };

  const setAdminUserState = (isAdmin: boolean) => {
    dispatch({
      type: "SET_CLOUD_ADMIN",
      payload: {
        isCloudAdmin: isAdmin,
      },
    });
  };

  const deleteUserState = () => {
    dispatch({
      type: "AUTH_STATE_CHANGED",
      payload: {
        isAuthenticated: false,
        isPhone: false,
        user: null,
      },
    });
  };

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        if (user) {
          let docRef = db.collection("User").doc(user.uid);

          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                if (doc.data().phone) {
                  history.push("/");
                } else {
                  history.push("/join2");
                }
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        }
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert("이메일 또는 비밀번호가 올바르지 않습니다.");
      });
  };

  // 클라우드
  const signInWithEmailAndPasswordCloud = (email: string, password: string, isAdmin = false): Promise<any> => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        if (clientId) {
          let userRef = db.collection("User").doc(userCredential?.user?.uid);
          userRef.get().then((doc) => {
            if (doc.exists) {
              let cloudData = doc.data().cloudData[`${clientId}`];
              if (!cloudData.isAdmin) {
                setAdminUserState(false);
                history.push(`/${clientId}`);
              }
            } else {
              setAdminUserState(true);
              history.push(`/${clientId}`);
            }
          });
        }
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert("이메일 또는 비밀번호가 올바르지 않습니다.");
      });
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
  };

  const signInWithKakao = (): Promise<any> => {
    return Kakao.Auth.authorize({
      redirectUri: "https://platform.onthewall.io/login",
    });
  };

  const getKakaoAccessToken = (authorizationCode): Promise<any> => {
    return axios
      .post("https://us-central1-gd-virtual-staging.cloudfunctions.net/getKakaoToken", {
        authorizationCode,
      })
      .then((res) => {
        Kakao.Auth.setAccessToken(res.data.access_token);
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            const uid = "kakao" + response.id;
            const nickname = response.properties.nickname;
            const email = response.kakao_account.email;

            let docRef = db.collection("User").doc(uid);
            docRef
              .get()
              .then((doc) => {
                if (doc.exists) {
                  //
                  // 이메일 저장 위해 임시로 필요 (시작)
                  //
                  if (!doc.data().email && email) {
                    docRef.update({
                      email,
                    });
                  }
                  //
                  // 이메일 저장 위해 임시로 필요 (끝)
                  //

                  if (doc.data().isDeleted) {
                    alert("탈퇴 아이디입니다. 재가입 문의는 고객센터로 연락주세요.");
                    throw new Error("탈퇴 회원");
                  }

                  const kakaoUser = {
                    id: uid,
                    avatar: doc.data().avatar,
                    email: doc.data().email || email,
                    name: doc.data().userName || nickname,
                    phone: doc.data().phone,
                    introduce: doc.data().introduce,
                    countryCode: doc.data().countryCode,
                    agreeMarketing: doc.data().agreeMarketing,
                    agreeEmail: doc.data().agreeEmail,
                    agreeKakao: doc.data().agreeKakao,
                  };
                  saveUserState(kakaoUser, null, doc.data().phone, "kakao");
                  if (doc.data().phone) {
                    history.push("/");
                  } else {
                    history.push("/join2");
                  }
                } else {
                  updateUserProfile(uid, email, nickname, "kakao");
                }
              })
              .catch((error) => {
                console.log("Error getting document:", error);
              });
          },
          fail: function (error) {
            console.log(error);
          },
        });
      })
      .catch((err) => {
        console.log("에러");
        console.log(err);
      });
  };

  // 클라우드
  const getKakaoAccessTokenCloud = (authorizationCode): Promise<any> => {
    return axios
      .post("https://us-central1-gd-virtual-staging.cloudfunctions.net/getKakaoTokenCloud", {
        authorizationCode,
      })
      .then((res) => {
        Kakao.Auth.setAccessToken(res.data.access_token);
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            const uid = "kakao" + response.id;
            const nickname = response.properties.nickname;
            const email = response.kakao_account.email;

            let docRef = db.collection("User").doc(uid);
            docRef
              .get()
              .then((doc) => {
                if (doc.exists) {
                  if (doc.data().isDeleted) {
                    alert("탈퇴 아이디입니다. 재가입 문의는 고객센터로 연락주세요.");
                    throw new Error("탈퇴 회원");
                  }

                  const kakaoUser = {
                    id: uid,
                    avatar: doc.data().avatar,
                    email: doc.data().email || email,
                    name: doc.data().userName || nickname,
                    phone: doc.data().phone,
                    introduce: doc.data().introduce,
                    countryCode: doc.data().countryCode,
                    agreeMarketing: doc.data().agreeMarketing,
                    agreeEmail: doc.data().agreeEmail,
                    agreeKakao: doc.data().agreeKakao,
                  };
                  saveUserState(kakaoUser, null, doc.data().phone, "kakao");
                  if (doc.data().phone) {
                    history.push(`/${clientId}`);
                  } else {
                    history.push(`/${clientId}/join2`);
                  }
                } else {
                  updateUserProfile(uid, email, nickname, "kakao");
                }
              })
              .catch((error) => {
                console.log("Error getting document:", error);
              });
          },
          fail: function (error) {
            console.log(error);
          },
        });
      })
      .catch((err) => {
        console.log("에러");
        console.log(err);
      });
  };

  const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = (): Promise<void> => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        console.log("sign out");
      })
      .catch((error) => {
        // An error happened.
        console.log("sign out error");
      })
      .finally(() => {
        Kakao.Auth.logout(function () {
          // console.log(Kakao.Auth.getAccessToken());
        });
        deleteUserState();
      });
  };

  useEffect(() => {
    let kakaoUser = null;
    Kakao.API.request({
      url: "/v2/user/me",
      success: function (response) {
        const uid = "kakao" + response.id;
        let userRef = db.collection("User").doc(uid);
        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              kakaoUser = {
                id: doc.data().uid,
                avatar: doc.data().avatar,
                email: doc.data().email,
                name: doc.data().userName || doc.data().email,
                phone: doc.data().phone,
                introduce: doc.data().introduce,
                countryCode: doc.data().countryCode,
                agreeMarketing: doc.data().agreeMarketing,
                agreeEmail: doc.data().agreeEmail,
                agreeKakao: doc.data().agreeKakao,
              };
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          })
          .finally(() => {
            return unsubscribe();
          });
      },
      fail: function (error) {
        return unsubscribe();
      },
    });

    const unsubscribe = () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user?.uid) {
          let phone = "";
          let social = "";
          let userRef = db.collection("User").doc(user.uid);
          userRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                phone = doc.data().phone;
                social = doc.data().social;
              }
              saveUserState(kakaoUser, doc.data(), phone, social);
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
          console.log("awoeijfowaiejf", clientId);
          if (clientId) {
            let userRef = db.collection("User").doc(user?.uid);
            userRef.get().then((doc) => {
              if (doc.exists) {
                let cloudData = doc.data().cloudData[`${clientId}`];
                if (!cloudData.isAdmin) {
                  setAdminUserState(false);
                  history.push(`/${clientId}`);
                } else {
                  setAdminUserState(true);
                  history.push(`/${clientId}`);
                }
              } else {
              }
            });
          }
        } else if (kakaoUser) {
          saveUserState(kakaoUser, user, kakaoUser.phone, "kakao");
        } else {
          deleteUserState();
        }
      });
    };
  }, [dispatch]);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "FirebaseAuth",
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithEmailAndPasswordCloud,
        signInWithGoogle,
        signInWithKakao,
        getKakaoAccessToken,
        getKakaoAccessTokenCloud,
        logout,
        updateUserProfile,
        updateUserState,
        saveUserState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
