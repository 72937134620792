import { all, fork, put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "../reducers/music";
import { firestore, rsfDB } from "../lib/firebase";

// call은 동기, fork는 비동기 요청
function* getMusic(action) {
  const { id } = action;
  try {
    const musicData = yield call(rsfDB.getDocument, `Music/${id}`);
    yield put({
      type: actions.GET_MUSIC_SUCCESS,
      data: musicData.data(),
    });
  } catch (err) {
    yield put({
      type: actions.GET_MUSIC_FAILURE,
      error: err.message,
    });
  }
}
function* getAllMusicsData(action) {
  const { isPublic } = action;
  try {
    let query = null;
    if (isPublic) {
      query = firestore()
        .collection("Music")
        .where("isDeleted", "==", false)
        .where("isPublic", "==", true)
        .orderBy("order", "asc")
        .orderBy("category")
        .orderBy("description");
    } else {
      query = firestore().collection("Music").where("isDeleted", "==", false);
    }
    const snapshot = yield call(rsfDB.getCollection, query);

    const data = [];
    snapshot.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data()?.createdAt?.seconds * 1000,
        updatedAt: doc.data()?.updatedAt?.seconds * 1000,
      });
    });

    yield put({
      type: actions.GET_ALL_MUSICS_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: actions.GET_ALL_MUSICS_FAILURE,
      error: err.message,
    });
  }
}
function* postMusicData(action) {
  const { data } = action;

  try {
    yield call(rsfDB.addDocument, `Music`, {
      ...data,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.POST_MUSIC_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_MUSIC_FAILURE,
      error: err.message,
    });
  }
}
function* updateMusicData(action) {
  const { id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `Music/${id}`, {
      ...data,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.UPDATE_MUSIC_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_MUSIC_FAILURE,
      error: err.message,
    });
  }
}
function* deleteMusicData(action) {
  const { id } = action;
  try {
    yield call(rsfDB.updateDocument, `Music/${id}`, {
      isDeleted: true,
    });
    yield put({
      type: actions.DELETE_MUSIC_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_MUSIC_FAILURE,
      error: err.message,
    });
  }
}

function* watchGetMusic() {
  yield takeEvery<string>(actions.GET_MUSIC_REQUEST, getMusic);
}
function* watchGetAllMusics() {
  yield takeEvery<string>(actions.GET_ALL_MUSICS_REQUEST, getAllMusicsData);
}
function* watchPostMusic() {
  yield takeLatest<string>(actions.POST_MUSIC_REQUEST, postMusicData);
}

function* watchUpdateMusic() {
  yield takeLatest<string>(actions.UPDATE_MUSIC_REQUEST, updateMusicData);
}
function* watchDeleteMusic() {
  yield takeLatest<string>(actions.DELETE_MUSIC_REQUEST, deleteMusicData);
}
export default function* musicSaga() {
  yield all([fork(watchGetMusic), fork(watchPostMusic), fork(watchGetAllMusics), fork(watchUpdateMusic), fork(watchDeleteMusic)]);
}
