import {
  all,
  fork,
  put,
  call,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { actions } from "../reducers/space";
import { firestore, rsfDB } from "../lib/firebase";

// call은 동기, fork는 비동기 요청
function* getSpace(action) {
  const { id } = action;
  try {
    const spaceData = yield call(rsfDB.getDocument, `Space/${id}`);
    yield put({
      type: actions.GET_SPACE_SUCCESS,
      data: spaceData.data(),
    });
  } catch (err) {
    yield put({
      type: actions.GET_SPACE_FAILURE,
      error: err.message,
    });
  }
}
function* getAllSpacesData(action) {
  const { isPublic, id } = action;
  try {
    let query = null;
    if (isPublic) {
      query = firestore()
        .collection("Space")
        .where("isDeleted", "==", false)
        .where("isPublic", "==", true)
        .orderBy("order", "asc");
    } else {
      if (id) {
        query = firestore()
          .collection("Space")
          // .where("order", ">=", 0)
          .where("isDeleted", "==", false)
          .where("isPublic", "==", false)
          .where("owner", "array-contains", id)
      }
    }
    const snapshot = yield call(rsfDB.getCollection, query);

    const data = [];
    snapshot.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data()?.createdAt?.seconds * 1000,
        updatedAt: doc.data()?.updatedAt?.seconds * 1000,
      });
    });

    yield put({
      type: actions.GET_ALL_SPACES_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: actions.GET_ALL_SPACES_FAILURE,
      error: err.message,
    });
  }
}
function* postSpaceData(action) {
  const { data } = action;

  try {
    yield call(rsfDB.addDocument, `Space`, {
      ...data,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.POST_SPACE_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_SPACE_FAILURE,
      error: err.message,
    });
  }
}
function* updateSpaceData(action) {
  const { id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `Space/${id}`, {
      ...data,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.UPDATE_SPACE_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_SPACE_FAILURE,
      error: err.message,
    });
  }
}
function* deleteSpaceData(action) {
  const { id } = action;
  try {
    yield call(rsfDB.updateDocument, `Space/${id}`, {
      isDeleted: true,
    });
    yield put({
      type: actions.DELETE_SPACE_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_SPACE_FAILURE,
      error: err.message,
    });
  }
}

function* watchGetSpace() {
  yield takeEvery<string>(actions.GET_SPACE_REQUEST, getSpace);
}
function* watchGetAllSpaces() {
  yield takeEvery<string>(actions.GET_ALL_SPACES_REQUEST, getAllSpacesData);
}
function* watchPostSpace() {
  yield takeLatest<string>(actions.POST_SPACE_REQUEST, postSpaceData);
}

function* watchUpdateSpace() {
  yield takeLatest<string>(actions.UPDATE_SPACE_REQUEST, updateSpaceData);
}
function* watchDeleteSpace() {
  yield takeLatest<string>(actions.DELETE_SPACE_REQUEST, deleteSpaceData);
}
export default function* spaceSaga() {
  yield all([
    fork(watchGetSpace),
    fork(watchPostSpace),
    fork(watchGetAllSpaces),
    fork(watchUpdateSpace),
    fork(watchDeleteSpace),
  ]);
}
