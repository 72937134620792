import { all, fork, put, call, takeEvery, take, cancel } from 'redux-saga/effects';
import { actions } from '../reducers/global';
import { GLOBAL_DATA_KEY } from '../constants';
import { firestore, rsfDB } from '../lib/firebase';

// call은 동기, fork는 비동기 요청
function* syncGlobalData() {
  const task = yield fork(rsfDB.syncDocument, `Global/${GLOBAL_DATA_KEY}`, {
    successActionCreator: data => ({
      type: actions.SYNC_GLOBAL_DATA_SUCCESS,
      data: data.data(),
    }),
    failureActionCreator: err => ({
      type: actions.SYNC_GLOBAL_DATA_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_GLOBAL_DATA_DONE);
  yield cancel(task);
}
function* getGlobalData() {
  try {
    const globalData = yield call(rsfDB.getDocument, `Global/${GLOBAL_DATA_KEY}`);
    yield put({
      type: actions.GET_GLOBAL_DATA_SUCCESS,
      data: globalData.data(),
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_GLOBAL_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* getDocentIconType() {
  try {
    const snapshot = yield call(rsfDB.getCollection, firestore().collection(`DocentIcon`).orderBy('order'));
    const data = [];
    snapshot.forEach(doc => {
      data.push({ ...doc.data(), id: doc.id });
    });
    yield put({
      type: actions.GET_DOCENT_ICON_TYPE_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: actions.GET_DOCENT_ICON_TYPE_FAILURE,
      error: err.message,
    });
  }
}
function* updateGlobalData(action) {
  const { target, value }: { target: string; value: string } = action;
  try {
    yield call(rsfDB.updateDocument, `Global/${GLOBAL_DATA_KEY}`, target, value);
    yield put({
      type: actions.UPDATE_GLOBAL_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_GLOBAL_DATA_FAILURE,
      error: err.message,
    });
  }
}

function* watchSyncGlobalData() {
  yield takeEvery<string>(actions.SYNC_GLOBAL_DATA_REQUEST, syncGlobalData);
}
function* watchGetGlobalData() {
  yield takeEvery<string>(actions.GET_GLOBAL_DATA_REQUEST, getGlobalData);
}

function* watchUpdateGlobalData() {
  yield takeEvery<string>(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData);
}
function* watchGetDocentIconType() {
  yield takeEvery<string>(actions.GET_DOCENT_ICON_TYPE_REQUEST, getDocentIconType);
}
export default function* globalSaga() {
  yield all([fork(watchSyncGlobalData), fork(watchUpdateGlobalData), fork(watchGetGlobalData), fork(watchGetDocentIconType)]);
}
