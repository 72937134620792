import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { actions } from '../reducers/product';
import { rsfDB } from '../lib/firebase';

// call은 동기, fork는 비동기 요청
function* setProduct(action) {
  const { exhibitionId, plan } = action;
  try {
    // const musicData = yield call(rsfDB.getDocument, `Music/${id}`);
    yield put({
      type: actions.SET_PRODUCT_SUCCESS,
      data: action,
    });
  } catch (err) {
    yield put({
      type: actions.SET_PRODUCT_FAILURE,
      error: err.message,
    });
  }
}

function* watchSetProduct() {
  yield takeEvery<string>(actions.SET_PRODUCT_REQUEST, setProduct);
}

export default function* globalSaga() {
  yield all([fork(watchSetProduct)]);
}
