import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
// import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from '../utils/reducerUtils';

interface SimulationState {
  count: number;
  isEditable: boolean;
  defaultDistance: number;
  language: 'kr' | 'en';
}
// 시뮬레이션 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: SimulationState = {
  count: 0,
  isEditable: true,
  defaultDistance: 0,
  language: 'kr',
};

// 액션들의 집합.
export const actions = {
  ADD_COUNT: 'ADD_COUNT',
  SUBTRACT_COUNT: 'SUBTRACT_COUNT',
  RESET_COUNT: 'RESET_COUNT',
  SET_EDITABLE: 'SET_EDITABLE',
  SET_DEFAULT_DISTANCE: 'SET_DEFAULT_DISTANCE',
  TOGGLE_LANGUAGE: 'TOGGLE_LANGUAGE',
};

// 배치 시뮬레이션에 배치된 좌석 수를 더한다.
export const addCountAction = (amount: number) => ({
  type: actions.ADD_COUNT,
  amount,
});

// 배치 시뮬레이션에 배치된 좌석 수를 뺀다.
export const subtractCountAction = (amount: number) => ({
  type: actions.SUBTRACT_COUNT,
  amount,
});

// 배치 시뮬레이션에 배치된 좌석 초기화 한다.
export const resetCountAction = () => ({
  type: actions.RESET_COUNT,
});

// 수정 가능하도록 변경한다.
export const setEditableAction = (isEditable: boolean) => ({
  type: actions.SET_EDITABLE,
  isEditable,
});

// 거리두기 거리를 설정한다.
export const setDefaultDistanceAction = (value: number) => ({
  type: actions.SET_DEFAULT_DISTANCE,
  value,
});

// 언어 세팅을 변경한다.
export const toggleLanguageAction = () => ({
  type: actions.TOGGLE_LANGUAGE,
});

// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.ADD_COUNT:
        draft.count += action.amount;
        break;
      case actions.SUBTRACT_COUNT:
        draft.count -= action.amount;
        break;
      case actions.RESET_COUNT:
        draft.count = 0;
        break;
      case actions.SET_EDITABLE:
        draft.isEditable = action.isEditable;
        break;
      case actions.SET_DEFAULT_DISTANCE:
        draft.defaultDistance = action.value;
        break;
      case actions.TOGGLE_LANGUAGE:
        if (draft.language === 'en') draft.language = 'kr';
        else if (draft.language === 'kr') draft.language = 'en';
        break;
      default:
        break;
    }
  });

export default reducer;
