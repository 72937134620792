import { Vector3 } from "three";

export enum Category {
  Sofa = "Sofa",
  EndTable = "End Table",
  ArmChair = "Arm Chair",
  CoffeTable = "Coffee Table",
}

export type ItemDesc = {
  name: string;
  url: string;
  position: Vector3;
  rotation: Vector3;
  scale: Vector3;
  categories: Category[];
};

export type SlotDesc = {
  name: string;
  category: Category;
};

export type Instance<T> = {
  desc: T;
  sceneObject: any;
};

export interface ptInfo {
  originalImageUrl: string;
  thumbnailImageUrl: string;
  width: number;
  height: number;
  frameThick: number;
  title: string;
  artist: string;
  desc: string;
  id: string;
}

export interface videoInfo {
  videoUrl: string;
  width: number;
  height: number;
  frameThick: number;
  title: string;
  artist: string;
  desc: string;
  id: string;
}

export interface audioInfo {
  audioUrl: string;
  buttonUrl: string;
  title: string;
  desc: string;
  id: string;
}

export interface webLinkInfo {
  url: string;
  name: string;
  id: string;
}
